import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';

const GameControls = ({ isPaused }) => {
  return (
    <Card className="text-center">
      <Card.Body className="d-flex align-items-center justify-content-center">
    
        <ListGroup variant="flush">
          <ListGroup.Item>➖ Riduci il punteggio</ListGroup.Item>
          <ListGroup.Item>{isPaused ? '▶️ Avvia il timer' : '⏸ Pausa il timer'}</ListGroup.Item>
          <ListGroup.Item>➕ Aumenta il punteggio</ListGroup.Item>
        </ListGroup>

        <ListGroup variant="flush">
          <ListGroup.Item>🖐 Passo</ListGroup.Item>
          <ListGroup.Item>🔄 Ricomincia il gioco</ListGroup.Item>
          <ListGroup.Item>💬 Genera parola</ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default GameControls;
