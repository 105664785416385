import React, { useState } from 'react';
import '../App.css';

const Skip = () => {
  const [button1Visible, setButton1Visible] = useState(true);
  const [button2Visible, setButton2Visible] = useState(true);

  const handleButton1Click = () => {
    setButton1Visible(false);
  };

  const handleButton2Click = () => {
    setButton2Visible(false);
  };

  return (
    <div className='d-flex align-items-center justify-content-center'>
      {button1Visible && (
        <button className="generic-button small-button" onClick={handleButton1Click}>
          🖐
        </button>
      )}

      {button2Visible && (
        <button className="generic-button small-button" onClick={handleButton2Click}>
         🖐
        </button>
      )}
    </div>
  );
};

export default Skip;
