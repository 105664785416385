import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import '../App.css';  

const CustomNavbar = () => {
  return (
    <Navbar bg="light" expand="lg" className="d-flex">
      <Container className="align-items-center custom-container">
        <Navbar.Brand href="#" className="align-items-center ">Intesa Vincete</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto mx-auto">
            <Nav.Link href="#home" className="mx-auto">Home</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
