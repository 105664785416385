import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar } from 'react-bootstrap';
import Skip from './components/Skip';
import GameControls from './components/GameControls';

const Game = () => {
  const [timer, setTimer] = useState(60);
  const [score, setScore] = useState(0);
  const [steps, setSteps] = useState(2);
  const [currentWord, setCurrentWord] = useState('');
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [isPaused, setIsPaused] = useState(true); // Imposta il tempo di default in pausa
  const [wordList, setWordList] = useState([]);
  const [screen, setScreen] = useState('start');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('wordList.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Dati caricati:', data);
        setWordList(data);
      } catch (error) {
        console.error('Errore nel caricamento delle parole:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isGameStarted && !isPaused) {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isGameStarted, isPaused]);

  useEffect(() => {
    const generateRandomWord = () => {
      const randomIndex = Math.floor(Math.random() * wordList.length);
      setCurrentWord(wordList[randomIndex]);
    };

    if (isGameStarted && wordList.length > 0) {
      generateRandomWord();
    }
  }, [isGameStarted, wordList]);

  useEffect(() => {
    // Implementa la logica di sincronizzazione qui
    // Usa WebSocket o altri mezzi per comunicare tra i dispositivi
  }, [timer, score, steps, currentWord]);

  const handleStartGame = () => {
    setIsGameStarted(true);
    setIsPaused(true); // Imposta il tempo in pausa quando inizia il gioco
    setScreen('game');
  };

  const handleScoreChange = () => {
    setScore((prevScore) => prevScore + 1);
  };

  const handleScoreMinor = () => {
    if (score > 0) {
      setScore((prevScore) => prevScore - 1);
    } else {
      setScore(0);
    }
  };

  const handleRestartGame = () => {
    setTimer(60);
    setScore(0);
    setSteps(2);
    setIsGameStarted(false);
    setCurrentWord('');
    setIsPaused(true); // Imposta il tempo in pausa al riavvio del gioco
    setScreen('start');
  };

  const handleGenerateWord = () => {
    if (isGameStarted && wordList.length > 0) {
      const randomIndex = Math.floor(Math.random() * wordList.length);
      setCurrentWord(wordList[randomIndex]);
    }
  };

  const handlePauseToggle = () => {
    setIsPaused((prevIsPaused) => !prevIsPaused);
  };

  return (
    <div>
      <Navbar />

      <div className={`container ${screen === 'start' ? 'start-screen' : 'game-screen'}`}>

        {screen === 'start' && (
          <div className="start-container text-center mt-5">
            <button className="generic-button" onClick={handleStartGame}>Inizia Gioco</button>
          </div>
        )}

        {screen === 'game' && (
          <div>

            <Skip />

            <br></br>

            <div className='d-flex align-items-center justify-content-center'>
              <p className="time">:{timer}</p>
              <p className="word ">{currentWord}</p>
              <p className="score">{score}</p>
            </div>

            <br></br>

            <div className='d-flex align-items-center justify-content-center'>
              <button className="generic-button" onClick={handleRestartGame}>🔄</button>
              <button className="generic-button" onClick={handleGenerateWord}>💬</button>
            </div>

            <br></br>

            <div className='d-flex align-items-center justify-content-center'>
              <button className="generic-button" onClick={handleScoreMinor}>➖</button>
              <button className="generic-button" onClick={handlePauseToggle}>
                {isPaused ? '▶️' : '⏸'}
              </button>
              <button className="generic-button" onClick={handleScoreChange}>➕</button>
            </div>

            <br></br>

           <GameControls isPaused={isPaused} />

          </div>
        )}

      </div>
    </div>
  );
};

export default Game;
