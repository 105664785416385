import React from 'react';
import Game from './Game';
import Navbar from './components/Navbar';
import './App.css';

function App() {
  return (
    <div>
      <Navbar></Navbar>
      <Game></Game>
    </div>

  );
}

export default App;
